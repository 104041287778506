import Com from "../services/ComService";
import SearchNeedle from "../services/SearchNeedle";
import { ItemAdminDTO } from "./ItemAdminDTO";

export class ItemsDTO {
  items: any[] = []
  groupId: number = -1

  sourceUrl = ''
  newItemUrl = ''

  constructor(data: IConstructor) {
    this.groupId = data.groupId
  }

  public async getFromServer(cache?: string) {
    const cacheString = cache || 'true'
    const items = await Com(`POST:${cacheString}:${this.sourceUrl}`, {
      groupId: this.groupId
    }, 3) as any[]
    this.initItems(items)
  }

  public initItems(items: any[]) {
    // return new ItemAdminDTO(i)
  }
  
  public getItem(id: number) {
    return this.items.find(i => i.id === id)
  }

  public getAll(needle?: string) {
    return this.items.filter(i => SearchNeedle(i.name, needle || '')) as ItemAdminDTO[]
  }
}

export interface IConstructor {
  groupId: number,
}