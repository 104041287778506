/**
 * This function finds all Strings in a text
 * @param hay The String which has to be searched
 * @param needle The String or a list of strings - seperated by ' ' to be found
 * @returns Boolean. True if All Strings have been found
 */
export default function SearchNeedle(hay: string, needle: string): boolean {
  if (needle === '') { return true }
  const parts = needle.split(' ')
  return parts.every(part => {
    const scope = new RegExp(part, 'i')
    return hay.search(scope) > -1
  })
}
