import { useState } from "react";
import { Trash } from "react-feather";
import ScheduleAdminDTO from "../../dto/ScheduleAdminDTO";
import TimeSelector from "../Input/TimeSelector";
import DateSelector from "../Input/DateSelector";
import DaySelector from "../Input/DaySelector";
import TimeMatrixSelect from "./TimeMatrixSelect";

export default function Schedule (props: {
  schedule: ScheduleAdminDTO
  cb?: () => void,
}) {
  const schedule = props.schedule
  const slot = schedule.slot
  const [rerender, setRerender] = useState(0)
  console.log('SCHEDULE', schedule.weekdays)
  if (schedule.weekdays.search(/[,oirs]/) > -1) {
    // Legacy Mode
    return <div className={`w3-card w3-margin-top w3-padding w3-row`}>
      <div className={`schedule-slot-holder w3-col s12 slot-${slot.id}`}>
        {
          schedule.slot.name
        }
      </div>
      
      <DaySelector
        days={schedule.weekdays}
        newDays={async (newDays) => {
          await schedule.setWeekdays(newDays)
          setRerender(rerender + 1)
          if (props.cb) { props.cb() }
        }}
      />
      <TimeSelector
        title={'Tägliche Startzeit'}
        currentTime={schedule.startTime}
        newTime={async (newTime) => {
          await schedule.setStartTime(newTime)
          setRerender(rerender + 1)
          if (props.cb) { props.cb() }
        }}
        fallbackTime='00:00'
      />
      <TimeSelector
        title={'Tägliche Endzeit'}
        currentTime={schedule.endTime}
        newTime={async (newTime) => {
          await schedule.setEndTime(newTime)
          setRerender(rerender + 1)
          if (props.cb) { props.cb() }
        }}
        fallbackTime='24:00'
      />
      <DateSelector
        title='Start Datum'
        value={schedule.start}
        newValue={(val) => {
          schedule.setStart(val)
          setRerender(rerender + 1)
        }}
      />
      <DateSelector
        title='End Datum'
        value={schedule.end}
        newValue={(val) => {
          schedule.setEnd(val)
          setRerender(rerender + 1)
        }}
        mode='evening'
      />
      <SelectPriority schedule={schedule} />
      <button
        onClick={async () => {
          if (!window.confirm('Regel wirklich löschen?')) { return }
          await schedule.delete()
          setRerender(rerender + 1)
          if (props.cb) { props.cb() }
        }}
      ><Trash /></button>
    </div>
  }
  // Standard Version:
  return <div className={`w3-card w3-margin-top w3-padding w3-row`}>
    <div className={`schedule-slot-holder w3-col s12 slot-${slot.id}`}>
      {
        schedule.slot.name
      }
    </div>
    <TimeMatrixSelect
      externalId={schedule.id}
      timeString={schedule.weekdays}
      change={async (newDays) => {
        console.log('setNewDays', newDays)
        await schedule.setWeekdays(newDays)
          // setRerender(rerender + 1)
          // if (props.cb) { props.cb() }
      }}
    />
    <DateSelector
      title='Start Datum'
      value={schedule.start}
      newValue={(val) => {
        schedule.setStart(val)
        setRerender(rerender + 1)
      }}
    />
    <DateSelector
      title='End Datum'
      value={schedule.end}
      newValue={(val) => {
        schedule.setEnd(val)
        setRerender(rerender + 1)
      }}
      mode='evening'
    />
    <SelectPriority schedule={schedule} />
    <button
      onClick={async () => {
        if (!window.confirm('Regel wirklich löschen?')) { return }
        await schedule.delete()
        setRerender(rerender + 1)
        if (props.cb) { props.cb() }
      }}
    ><Trash /></button>
  </div>
}

export function SelectPriority(props: {
  schedule: ScheduleAdminDTO
}) {
  const [value, setValue] = useState(props.schedule.priority)
  const Priorities = [
    {
      value: 1,
      name: 'Füller',
      color: '#aaa',
      fgColor: '#000',
    },
    {
      value: 10,
      name: 'Normal',
      color: '#4CAF50',
      fgColor: '#fff',
    },
    {
      value: 50,
      name: 'Überschreiber',
      color: 'red',
      fgColor: '#fff',
    },
    {
      value: 100,
      name: 'TOP',
      color: 'purple',
      fgColor: '#fff',
    },
  ]
  return <div className={`SelectPriority`}>
    <span
      title="Eine höere Priorität bewirkt, dass zu einem Zeitpunkt nur Dinge mit dieser Priorität angezeigt werden. Die höchste Priorität gewinnt!"
    >Priorität</span>
    {
      Priorities.map((p, index) => {
        const active = p.value === value
        return <button
          className={``}
          style={{
            backgroundColor: active ? p.color : '#fff',
            color: active ? p.fgColor : '#000',
            border: '1px solid ' + p.color,
            cursor: 'pointer',
            margin: '4px',
          }}
          title={p.name}
          onClick={async () => {
            await props.schedule.setPriority(p.value)
            setValue(p.value)
          }}
        >{p.name}</button>
      })
    }
  </div>
}