export default function RadioSelect(props: {
  title: string,
  options: {value: string, name: string}[],
  default: string,
  cb: (value: string) => void,
  activeClass?: string,
  inactiveClass?: string,
  className?: string,
}) {
  return <div className='w3-margin-bottom'>
    <span className='w3-margin-right'>
    {props.title}
    </span>
    {props.options.map((o, i) => <button
      key={`${props.title}-${o.value}-${i}`}
      className={`${props.className || 'w3-button'} ${o.value === props.default ? (props.activeClass || 'w3-green') : (props.inactiveClass || 'w3-grey')}`}
      onClick={() => {
        props.cb(o.value)
      }}
    >{o.name}</button>)}
  </div>
}