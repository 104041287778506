import { useState } from "react";
import Com from "../../services/ComService";
import i18n from "../../services/i18n";
import Input from "../Input/Input";
import {  } from "react-router-dom"

enum Status {
  init,
  done,
  shortPassword,
}
export default function SetPasswordByToken() {
  const [Password, setPassword] = useState('')
  const [S, setS] = useState(Status.init)
  const hash = window.location.href.match(/[^/]+$/)
  const Content = () => {
    switch(S) {
      case Status.init:
      case Status.shortPassword:
        return <>
          <div className='w3-container w3-container-auto w3-padding w3-margin w3-center'>
            {i18n.get('passwordForgottenExplainer')}
          </div>
          <div className='w3-container w3-container-auto w3-padding w3-margin w3-center'>
            <Input
              value={Password}
              cb={(c) => setPassword(c)}
              placeholder={i18n.get('newPassword')}
            />
            <button
              className='w3-button w3-ripple w3-green'
              onClick={async () => {
                const r = await Com('PATCH:user/passwordmail', {
                  token: hash,
                  password: Password
                }, 1) as {status: string}
                if (r.status === 'short password') {
                  return setS(Status.shortPassword)
                }
                setS(Status.done)
              }}
            >Ok</button>
            {
              S === Status.shortPassword &&
              <div className='w3-card w3-auto w3-border w3-border-red w3-color-red w3-margin w3-padding'>
                {i18n.get('shortPassword')}
              </div>
            }
          </div>
        </>
      default:
        return <>
          <div className='w3-border w3-border-green w3-color-green w3-container-auto w3-margin w3-padding'>
            {i18n.get('passwordChanged')}
          </div>
        </>
    }
  }
  
  return <div className='w3-container'>
    {Content()}
  </div>
}