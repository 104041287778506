import { useState } from "react"
import TimeMatrixDTO from "../../dto/TimeMatrixDTO"
import './TimeMatrix.scss'

export default function TimeMatrixSelect (props: {
  timeString?: string,
  change?: (newString: string) => void,
  externalId: number,
}) {
  const [m] = useState(new TimeMatrixDTO({
    timeString: props.timeString
  }))
  const [rerender, setRerender] = useState(0)
  return <div>
    {
      m.dateAllowed(new Date()) &&
      <div>Jetzt aktiv!</div>
    }
    {
      !m.dateAllowed(new Date()) &&
      <div>Jetzt inaktiv!</div>
    }
    <div className='w3-row'>
      {
        m.days.map(d => <div
          key={`header-${d}-${props.externalId}`}
          className='w3-col s1'
        >{d}</div>)
      }
    </div>
    <div className='w3-row'>
      
      {
        m.getWeekdays().map((weekDay, weekId) => {

          return <div key={`${weekDay.name}-${props.externalId}`} className='w3-col s1'>
            
            <div>
              {
                weekDay.points.map((p, index) => {
                  return <div
                    key={`${p.id}-${props.externalId}`}
                    title={p.title || `${p.hId} x ${p.vId}`}
                    className={`${m.highlightPoint(p) ? 'w3-green' : 'w3-grey'} timeMatrixSlot`}
                    onMouseDown={() => {
                      m.mouseDown(p)
                    }}
                    onMouseMove={() => {
                      if (m.mouseMove(p)) {
                        setRerender(rerender + 1)
                      }
                    }}
                    onMouseUp={() => {
                      m.mouseUp(p)
                      setRerender(rerender + 1)
                      if (props.change) {
                        props.change(m.returnString())
                      }
                    }}
                    ></div>
                })
              }
            </div>
          </div>
        })
      }
    </div>
  </div>
}
