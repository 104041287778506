import { useEffect, useState } from "react"
import { Plus } from "react-feather"
import { useDrag, useDrop } from 'react-dnd'
import { SlotAdminDTO } from "../../dto/SlotAdminDTO"
import { Snippeds } from "./Snippeds"
import ListItem from "./ListItem"
import './Admin.scss'
import Spinner from "../Spinner/Spinner"

enum Status {
  loading,
  rename,
  done,
}

type DropItem = {
  id: number,
  kind: string,
}

export function getAdminSlotIdFromUrl(): number {
  const raw = window.location.href.match(/group\/([0-9]+)\/slot\/([0-9]+)/)
  if (!raw) { return -1 }
  return parseInt(raw[2], 10)
}

export function Slot(props: {
  id?: number,
  reload?: () => void
}) {
  const id = props.id || getAdminSlotIdFromUrl()
  const [item] = useState(new SlotAdminDTO({id: id}))
  const [status, setStatus] = useState(Status.loading)
  const [Rerender, setRerender] = useState(0)
  const [currentId, setCurrentId] = useState(id)
  
  
  
  function reload(cache: 'true' | 'set') {
    item.setId(id)
    setCurrentId(id)
    setStatus(Status.loading)
    item.getFromServer(cache).then(u => {
      setRerender(Rerender + 1)
      setStatus(Status.done)
    })
    
  }
  if (currentId !== id) {
    reload('set')
    
  }
  useEffect(() => {
    // reload('set')
    item.getFromServer().then(() => {
      console.log('Screen Info 2', item)
      setStatus(Status.done)
    })
  }, [])

  const [{ isOver, canDrop }, drop] = useDrop(() => ({
    accept: 'listItem',
    canDrop: (e, monitor) => {
      const dropItem: {id: number, kind: string} = monitor.getItem()
      return dropItem.kind === 'snipped'
    },
    drop: (e, monitor) => {
      const dropItem = monitor.getItem() as DropItem
      console.log('do drop', dropItem)
      item.addSnipped(dropItem.id).then(() => reload('set'))
    },
    collect: monitor => ({
      isOver: !!monitor.isOver(),
      canDrop: !!monitor.canDrop()
    }),
  }), [item.id])
  if (status === Status.loading) {
    return <div className={`flexGrow flexRows Screen ${Rerender}`}>
      Lade Slot
      <Spinner />
    </div>
  }
  return <div className={`slot flexRows ${Rerender}`}>
    <div className='single-top'>
      <h2>Seite: {item.name}</h2>
    </div>
    <div className='flexColumns flexGrow'>
      <div className='usedsnippedList h100 flexRows'>
        <h2 className='h2Admin'>Genutzte Schnippsel</h2>
        <div
          ref={drop}
          className={`newRuleDropZone w3-center w3-padding ${(isOver && canDrop) ? 'w3-green' : 'w3-grey'}`}
        ><Plus /></div>
        <div className='flexGrow admin-scroll-panel'>
          {
            item.snippeds.map((s, index) => <ListItem
              key={`slot-${item.id}-snipped-${s.id}`}
              item={s}
              index={index}
              reload={() => {
                reload('set')
              }}
              open={() => {}}
              remove={async () => {
                await item.removeSnipped(s.id)
                reload('set')
              }}
              kind='snipped'
            />)
          }
        </div>
      </div>
      <div className='flexGrow flexRows'>
        <h2 className='h2Admin'>Verfügbare Schnippsel</h2>
        <Snippeds
          blackIds={item.snippeds.map(s => s.id)}
          className=''
        />
      </div>
    </div>
  </div>
}
