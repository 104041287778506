import { useState, useEffect } from 'react'
import loginService, { LoginStatus } from '../../services/LoginService'
import broadcastService from '../../services/BroadcastService'
import config from '../../config.json'
import i18n from '../../services/i18n'
import Input from '../Input/Input'
import Com from '../../services/ComService'

enum Status  {
  standard,
  fail,
  loggedIn,
  passwordmail
}

const Login = () => {
  const [status, setStatus] = useState(Status.standard)
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  useEffect(() => {
    broadcastService.subscribe('loginWindow', (key: string, r: any) => {
      if (key.search(/login|logout/) > -1) {
        if (r > 0) {
          // setStatus(Status.loggedIn)
          window.location.href = '/admin'
        }
        /*
         else if (Status.loggedIn) {
          setStatus(Status.standard)
        }
          */
      }

      loginService.IsLoggedIn().then(r => {
        if (r) {
          window.location.href = '/admin'
          // setStatus(Status.loggedIn)
        }
      })
    })
  }, [])
  if (status === Status.loggedIn) {
    return <button
      className='w3-btn'
      onClick={() => {
        loginService.Logout()
      }}
    >Log me out</button>
  }
  async function login() {
    const result = await loginService.Login(username, password)
    if (result === LoginStatus.admin) {
      window.location.href = config.adminTarget
      return
    } else if(result === LoginStatus.user) {
      window.location.href = config.loginTarget
      return
    }
    setStatus(Status.fail)
  }
  return <div className="w3-card-4 w3-auto w3-margin-top w3-margin-bottom">
    <header
      className='w3-container w3-blue'
    >
      <h2>{i18n.get('login')}</h2>
    </header>
    <div className='w3-container'>
      <Input
        className='w3-input'
        value={username}
        autofocus={true}
        placeholder={i18n.get('username')}
        cb={(username) => setUsername(username)}
      ></Input>
      <Input
        className='w3-input'
        value={password}
        type='password'
        cb={(password) => setPassword(password)}
        onEnter={(password) => {
          setPassword(password)
          login()
        }}
        placeholder={i18n.get('password')}
      ></Input>
    </div>
    {
      status === Status.fail &&
      <div className='w3-container'>
        {i18n.get('loginfailed')}
      </div>
    }
    {
      status === Status.passwordmail &&
      <div className='w3-container w3-border w3-border-yellow w3-margin w3-padding'>
        {i18n.get('passwordmailSend')}
      </div>
    }
    <div className='w3-margin'>
      <button
        className='w3-button w3-blue'
        onClick={() => {
          login()
        }}
      >{i18n.get('loginbuttontext')}</button>
      <button
        className='w3-button w3-right'
        onClick={async () => {
          await Com('POST:user/passwordmail', {
            email: username
          })
          setStatus(Status.passwordmail)
        }}
      >{i18n.get('passwordForgotten')}</button>
    </div>
    <footer className='w3-container'></footer>
  </div>
}
export default Login
