import { serverDateToDate } from "../services/DateService"

export class ScheduleDTO {
  id: number
  slotId: number
  active: boolean
  startDate: null | Date
  endDate: null | Date
  startTime: [number, number]
  endTime: [number, number]
  weekdays: string
  displayDuration: number
  priority: number

  constructor(item: ISchedule) {
    this.id = item.id
    this.slotId = item.slotId
    this.active = item.active
    this.startTime = timeString2Arr(item.startTime)
    this.endTime = timeString2Arr(item.endTime)
    this.weekdays = item.weekdays
    this.displayDuration = item.displayDuration
    this.priority = item.priority
    this.startDate = serverDateToDate(item.start)
    this.endDate = serverDateToDate(item.end)
    if (this.endDate !== null) {
      this.endDate.setHours(23)
      this.endDate.setMinutes(59)
    }
  }

  getDisplayDuration() {
    if (this.displayDuration > 0) {
      return this.displayDuration * 1000
    }
    return 5000
  }
}

function timeString2Arr(s: string): [number, number] {
  const p = s.split(':')
  return [parseInt(p[0], 10), parseInt(p[1], 10)]
}

export interface ISchedule {
  id: number
  slotId: number
  active: boolean
  startTime: string
  endTime: string
  weekdays: string
  displayDuration: number
  priority: number
  start: null | {date: string}
  end: null | {date: string}
}