import { useEffect, useState } from "react"
import { Key, Trash } from "react-feather"
import UserDTO from "../../dto/UserDTO"
import UsersDTO from "../../dto/UsersDTO"
import i18n from "../../services/i18n"
import Input from "../Input/Input"
import Spinner from "../Spinner/Spinner"

enum Status {
  loading,
  done,
  new,
  setPassword,
}

const RenderUser = (props: {
  user: UserDTO,
  cb: () => void
}) => {
  const [status, setStatus] = useState(Status.done)
  const [password, rememberPassword] = useState('')

  const Buttons = [
    {
      hideOnState: [],
      showOnState: [Status.setPassword],
      className: 'w3-button w3-yellow',
      onClick: async () => {
        await props.user.setPassword(password)
        setStatus(Status.done)
      },
      content: 'Ok'
    },
    {
      hideOnState: [],
      showOnState: [Status.setPassword],
      className: 'w3-button w3-green',
      onClick: () => {
        setStatus(Status.done)
      },
      content: 'Abbrechen'
    },
    {
      hideOnState: [Status.setPassword],
      showOnState: [],
      className: 'w3-button w3-yellow',
      onClick: () => {
        setStatus(Status.setPassword)
      },
      content: <Key />
    },
    {
      hideOnState: [],
      showOnState: [],
      className: 'w3-button w3-red',
      onClick: async () => {
        // await props.user.delete()
        if (window.confirm(`User ${props.user.email} wirklich löschen?`)) {
          await users.deleteUser({id: props.user.id})
        props.cb()
        }
      },
      content: <Trash />
    },
  ]
  return <div
    key={props.user.id}
    className='w3-container w3-container-auto w3-card w3-padding w3-margin w3-row'
  >
    <div className='w3-col s6'>
      {props.user.email}
    </div>
    <div className='w3-col s6 text-align-right'>
      {
        status === Status.setPassword &&
        <Input
          placeholder={i18n.get('newPassword') }
          value={password}
          cb={(pw) => {
            rememberPassword(pw)
          }}
        ></Input>
      }
      {
        Buttons.map((b, index) => <button
          key={`user-action-button-${props.user.id}-${index}`}
          className={`${b.className} ${(b.hideOnState.some(h => status === h) || b.showOnState.length > 0 && !b.showOnState.some(s => status === s)) ? 'w3-hide' : ''}`}
          onClick={() => {
            b.onClick()
          }}
        >
          {b.content}
        </button>)
      }
    </div>
  </div>
}

const users = new UsersDTO()

const UserAdd = (props: {
  cb: () => void
}) => {
  const [status, setStatus] = useState(Status.done)
  const [Email, setEmail] = useState('')
  const [Password, setPassword] = useState('')
  if (status === Status.done) {
    return <button
        className='w3-button w3-green w3-ripple'
        onClick={() => {
          setStatus(Status.new)
        }}
      >+</button>
  }
  return <div>
    <Input
      value={Email}
      placeholder='email'
      cb={(email) => setEmail(email)}
    ></Input>
    <Input
      value={Password}
      placeholder='password'
      cb={(password) => setPassword(password)}
    ></Input>
    <button
      className='w3-button w3-green'
      onClick={async () => {
        const result = await users.newUser({
          email: Email,
          password: Password
        })
        if (result !== 'okay') {
          alert(result)
          return
        }
        setStatus(Status.done)
        props.cb()
      }}
    >Los</button>
  </div>
}

export const SelectUserList = (props: {
  className?: string
  childClassName?: string
  users?: UserDTO[],
  exclude?: number[],
  cb: (id: number) => void
}) => {
  const [status, setStatus] = useState(props.users ? Status.done : Status.loading)
  useEffect(() => {
    users.getFromServer().then(() => {
      setStatus(Status.done)
    })
  }, [])
  const us = props.users ? props.users : users.getList().filter(u => !props.exclude || props.exclude.indexOf(u.id) === -1)
  if (status === Status.loading) {
    return <Spinner />
  }
  if (us.length === 0) {
    return <div className='w3-container w3-auto w3-center w3-padding w3-margin'>{i18n.get('noUserAvailable')}</div>
  }
  return <div>
    {
      us.map(u => <div
        key={`selectUserList-${u.id}`}
        className={props.childClassName || ''}
        onClick={() => {
          props.cb(u.id)
        }}
      >
        {
          u.email
        }
      </div>)
    }
  </div>
}

const UserList = (props: {}) => {
  const [status, setStatus] = useState(Status.loading)
  const [Render, setRender] = useState(0)
  useEffect(() => {
    users.getFromServer().then(() => {
      setStatus(Status.done)
    })
  }, [])
  if (status === Status.loading) {
    return <Spinner />
  }
  return <div>
    <UserAdd
      cb={() => { setRender(Render + 1) }}
    ></UserAdd>
    {users.getList().map(u => <RenderUser
      key={`RenderUser-${u.id}`}
      user={u}
      cb={() => { setRender(Render + 1) }}
    />)}
  </div>
}

export default UserList
