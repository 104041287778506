import { useState } from "react"
import GroupDTO from "../../dto/GroupDTO"
import JoditReact from "jodit-react-ts"
import 'jodit/build/jodit.min.css'
import Input from "../Input/Input"

type ContentType = {
  name: string,
  key1: string,
  key2: string,
  kind: 'text' | 'number' | 'boolean',
}

const ContentTypes: ContentType[] = [
  {
    name: 'Anzahl von Tagen die in Zukunft gebucht werden kann',
    key1: 'setting',
    key2: 'numberOfDaysToTheFuture',
    kind: 'number',
  },
  {
    name: 'Anzahl von Tagen nach denen vergangene Termine wieder gelöscht werden. Es wird hier immer mindestens 5 genommen.',
    key1: 'setting',
    key2: 'numberOfDaysToDeleteSlots',
    kind: 'number',
  },
  {
    name: 'Beschreibung über dem Kalender',
    key1: 'content',
    key2: 'aboveCalendar',
    kind: 'text',
  },
  {
    name: 'Beschreibung unter dem Kalender',
    key1: 'content',
    key2: 'belowCalendar',
    kind: 'text',
  },
  {
    name: 'Beschreibung über Buchungsanfrage',
    key1: 'content',
    key2: 'aboveBooking',
    kind: 'text',
  },
  {
    name: 'Beschreibung unter Buchungsanfrage',
    key1: 'content',
    key2: 'belowBooking',
    kind: 'text',
  },
  {
    name: 'Text bei erfolgreicher Buchung',
    key1: 'content',
    key2: 'successBooking',
    kind: 'text',
  },
]

export default function GContentEditor(props: {
  ugroup: GroupDTO
}) {
  const [Rerender, SetRerender] = useState(0)
  const g = props.ugroup
  return <div>
    <h3>Inhalte</h3>
    {
      ContentTypes.map((c) => <ContentItemEditor
        key={`${c.key1}-${c.key2}`}
        c={c}
        ugroup={g}
        cb={() => SetRerender(Rerender + 1)}
      />)
    }
  </div>
}

export function ContentItemEditor(props: {
  c: ContentType,
  ugroup: GroupDTO,
  cb: () => void
}) {
  const g = props.ugroup
  const c = props.c
  const currentProp = g.getProp(-1, c.key1, c.key2)
  const startVal = g.getPropV1(c.key1, c.key2, '')
  const id = currentProp?.id || -1
  // This is not a good Idea, since this will reset cursor position:
  const [value, setValue] = useState(startVal)
  let v = value + ''

  return <div className='w3-row w3-border w3-padding'>
    <h4>{c.name}</h4>
    {
      c.kind === 'text' &&
      <JoditReact
        onChange={(c) => {
          setValue(c)
        }}
        defaultValue={v || ''}
      />
    }
    {
      c.kind === 'number' &&
      <Input
        value={v}
        cb={(v) => setValue(v)}
      />
    }
    {
      c.kind === 'boolean' &&
      <>
        <button
          onClick={(v) => setValue('true')}
          className={`w3-button ${v === 'true' ? 'w3-green' : 'w3-grey'}`}
        >Ja</button>
        <button
          onClick={(v) => setValue('false')}
          className={`w3-button ${v !== 'true' ? 'w3-red' : 'w3-grey'}`}
        >Nein</button>
      </>
    }
    <button
      className='w3-button w3-ripple w3-green'
      onClick={async () => {
        await g.setProp({
          id: id,
          key1: c.key1,
          key2: c.key2,
          value1: value,
          value2: '',
        })
        props.cb()
      }}
    >Save</button>
  </div>
}
