const positions = [
  ['top left', 'top center', 'top right'],
  ['center left', 'center center', 'center right'],
  ['bottom left', 'bottom center', 'bottom right'],
]

export default function BGPosition(props: {
  title: string,
  default: string,
  cb: (value: string) => void,
  activeClass?: string,
  inactiveClass?: string,
  className?: string,
  externalId?: string,
}) {
  return <div className='w3-margin-bottom'>
    <span className='w3-margin-right'>
    {props.title}
    </span>
    {
      positions.map((p, i) => {
        return <div>
          {
            p.map((o, j) => {
              return <button
                key={`${props.title}-${o}-${i}-${j}-${props.externalId}`}
                className={`${props.className || 'w3-button w3-border'} ${o === props.default ? (props.activeClass || 'w3-green') : (props.inactiveClass || 'w3-grey')}`}
                onClick={() => {
                  props.cb(o)
                }}
              ></button>
            })
          }
        </div>
      })
    }
  </div>
}
