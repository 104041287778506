import { Outlet } from "react-router-dom"

const About = () => {
  return <div className='w3-container'>
    <h1>Willkommen beim Wechselrahmen</h1>
    <p>Dies ist ein virtueller Wechselrahmen.</p>
    <p>Es werden keinerlei Cookies oder sonstiges von uns gesetzt. Es entstehen ausschließlich technisch notwendige Daten, die für den Betrieb von Webseiten gebraucht werden.</p>
    <h2>Warum sehe ich diese Seite?</h2>
    <p>Der Anbieter hat den Inhalt unter einer sub-URL zur Verfügung gestellt. Wenn diese Url nicht bekannt ist, ist diese Seite ist daher, wenn sie diese URL nicht kennen leider nutzlos.</p>
    <Outlet></Outlet>
  </div>
}

export default About
