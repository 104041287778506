import { Outlet } from "react-router-dom"
import Menu from "../Menu/Menu"
import menuConfig from '../../config/groupMenu.json'
import { useEffect, useState } from "react"
import GroupDTO from "../../dto/GroupDTO"

enum Status {
  loading,
  done,
}

export function getGroupIdFromUrl(): number {
  const raw = window.location.href.match(/group\/([0-9]+)/)
  if (!raw) { return -1 }
  return parseInt(raw[1], 10)
}

export function Group(props: {}) {
  const groupId = getGroupIdFromUrl()
  const [group] = useState(new GroupDTO({id: groupId}))
  const [status, setStatus] = useState(Status.loading)
  useEffect(() => {
    group.getFromServer().then(() => {
      setStatus(Status.done)
    })
  }, [])
  return <div className='UGroup-main'>
    <Menu
      config={menuConfig}
      replace={[['{groupId}', `${groupId}`]]}
    />
    <Outlet></Outlet>
  </div>
}
