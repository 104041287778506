import { currentShortDay } from "../components/Input/DaySelector";
import { ISchedule, ScheduleDTO } from "./ScheduleDTO";
import TimeMatrixDTO from "./TimeMatrixDTO";

export class SchedulesDTO {
  items: ScheduleDTO[] = []

  setItems(items: ISchedule[]) {
    this.items = items.map(i => new ScheduleDTO(i))
  }

  getCurrent(): ScheduleDTO[] {
    const now = new Date()
    const currentHour = now.getHours()
    const currentMinute = now.getMinutes()
    const currentDay = currentShortDay()
    const allowedSchedules = this.items.filter(i => {
      if (!i.active) {
        console.log('Filter because of active', i)
        return false
      }

      // TODO Cutycapt schafft diesen Vergleich nicht!! Die Bedingungen werden nie wahr!
      // Debuggen, in dem die Inhalte auf das Bild gedruckt werden - auf diese Weise können wir herrausfinden, wo das problem liegt!
      // Filter by date:
      if (i.startDate !== null && now.getTime() < i.startDate.getTime()) {
        console.log('Filter because of startdate', i, now.getTime(), '<', i.startDate.getTime())
        return false
      }
      if (i.endDate !== null && i.endDate.getTime() < now.getTime()) {
        console.log('Filter because of enddate', i, i.endDate.getTime(), '<', now.getTime())
        return false
      }
        
      // Filter if Daymethod - this is the old style:
      if (i.weekdays.search(/[,oirs]/) > -1) {
        if (i.weekdays.search(currentDay) === -1) {
          console.log('Filter because of weekdayfilter', i)
          return false
        }
        if (currentHour < i.startTime[0] || (currentHour === i.startTime[0] && currentMinute < i.startTime[1])) {
          console.log('Filter because of weekdayfilter', i)
          return false
        }
        if (i.endTime[0] < currentHour || (currentHour === i.endTime[0] && i.endTime[1] < currentMinute)) {
          console.log('Filter because of weekdayfilter', i)
          return false
        }
        console.log('ProFilter because of weekdayfilter', i)
        return true
      } else {
        // Filter for TimeMatrixGrid
        const m = new TimeMatrixDTO({
          timeString: i.weekdays
        })
        if (m.dateAllowed(now)) {
          return true
        } else {
          console.log('Filter because of dateAllowed', i)
          return false
        }
      }
    })
    // console.log('before reduce', allowedSchedules,allowedSchedules.map(s => s.priority))
    const maxPriority = allowedSchedules.map(s => s.priority).reduce((max, current) => Math.max(max, current), 0)
    // Now only the highest priority will survive:
    return allowedSchedules.filter(s => {
      const isMaxPrio = s.priority === maxPriority
      if (!isMaxPrio) {
        console.log('Filter because of not max prio', s, '!=', maxPriority)
      }
      return isMaxPrio
    })
  }
}
