import Com from "../services/ComService"

export default class UserDTO {
  id: number
  email: string
  roles: string[]

  constructor(user: IUser) {
    this.id = user.id
    this.email = user.email
    this.roles = user.roles
  }

  public async delete() {
    await Com('DELETE:user', {
      id: this.id
    })
  }

  public async setPassword(password: string) {
    const r = await Com('PATCH:admin/user/password', {
      email: this.email,
      wurzel: password
    }, 1) as {status: string}
    return r.status
  }
}

export interface IUser {
  id: number
  email: string
  roles: string[]
}