import { SnippedDTO, SnippedKind } from "../../dto/SnippedDTO"
import './Snipped.scss'

export function Snipped(props: {
  item: SnippedDTO,
  editorVersion?: boolean,
  doRerender?: number,
}) {
  const item = props.item
  if (!item) { return null }
  const style = item.generateStyle()
  let className = props.editorVersion ? 'snippedDemo' : 'snippedDisplay'
  
  console.log('STYLE', style)
  return <>
    {
      (item.kind === SnippedKind.HTML) &&
      <div
        dangerouslySetInnerHTML={{__html: item.value}}
        className={className}
        style={style}
      ></div>
    }
    {
      (item.kind === SnippedKind.WEB2PIC) &&
      <div
        className={className}
        style={style}
      ></div>
    }
    {
      item.kind === SnippedKind.IFRAME &&
      <iframe
        title={`snipped-${item.id}`}
        src={`${prepareUrl(item.value)}`}
        width="1024"
        height="768"
        className={className}
        scrolling="no"
        style={style}
      ></iframe>
    }
  </>
}

function prepareUrl(url: string) {
  if (url.search('http') === -1) {
    return 'http://' + url
  }
  return url
}