import DatePicker from 'react-datepicker'
import "react-datepicker/dist/react-datepicker.css"

export default function DateSelector(props: {
  title: string,
  value: Date | null,
  newValue: (date: Date | null) => void,
  mode?: 'morning' | 'evening',
}) {
  return <div className='w3-row'>
    <div className='w3-col s12 m12'>
      {props.title}
    </div>
    <div className='w3-col s10 w3-display-container'>
        <DatePicker
          selected={props.value || null}
          onChange={async (date) => {
            if (props.mode === 'evening') {
              date?.setHours(23)
              date?.setMinutes(59)
              console.log('setting date evening', date, props.mode)
            } else {
              date?.setHours(0)
              date?.setMinutes(0)
              console.log('setting date morning', date, props.mode)
            }
            props.newValue(date)
          }}
          onBlur={async (date) => {
            
          }}
        />
      </div>
      <button
        className='w3-col s2'
        onClick={() => {
          props.newValue(null)
        }}
      >X</button>
  </div>
}