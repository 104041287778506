import { useState } from "react";
import { twoDigits } from "../../services/DateService";
import Input from "./Input";

export default function TimeSelector(props: {
  currentTime: string,
  newTime: (time: string) => void,
  fallbackTime: string,
  title: string,
}) {
  const [raw, setRaw] = useState(props.currentTime)
  return<div className='w3-row'>
    <div className='w3-col s12 m5'>
      {props.title}
    </div>
    <Input
      className='w3-col s12 m5'
      value={raw}
      cb={(newValue) => {
        newValue = sanitizeValue(newValue, props.fallbackTime)
        setRaw(newValue)
      }}
      onEnter={() => {
        props.newTime(sanitizeValue(raw, props.fallbackTime))
      }}
    />
    <button
      className='w3-col s2'
      onClick={() => {
      props.newTime(sanitizeValue(raw, props.fallbackTime))
    }}>
      ok
    </button>
  </div>
}

function sanitizeValue(s: string, fb: string): string {
  let h = 0
  let m = 0
  
  const hourScope = /([0-9]+)/
  const timeScope = /([0-9]+)[^0-9]([0-9]+)/
  if (s.search(timeScope) > -1) {
    const timeMatch = s.match(timeScope) as [string, string, string]
    h = parseInt(timeMatch[1], 10)
    m = parseInt(timeMatch[2], 10)
  } else if (s.search(hourScope) > -1) {
    const hourMatch = s.match(hourScope) as [string, string]
    h = parseInt(hourMatch[1], 10)
  } else {
    return fb
  }
  return `${twoDigits(h)}:${twoDigits(m)}`
}
