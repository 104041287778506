import { useState } from "react"
import { Edit, Eye, Minus, Trash } from "react-feather"
import { useDrag, useDrop } from 'react-dnd'
import Input from "../Input/Input"

enum Status {
  loading,
  new,
  rename,
  single,
  done,
}

export default function ListItem(props: {
  item: {
    id: number,
    name: string,
    delete: () => void,
    rename: (name: string, cb: () => void) => void
  },
  kind: string,
  index: number,
  reload: () => void,
  open?: () => void,
  remove?: () => void,
  className?: string,
}) {
  const item = props.item
  const [status, setStatus] = useState(Status.done)
  const [{isDragging}, drag] = useDrag(() => {
    return {
      type: 'listItem',
      item: {
        id: item.id,
        kind: props.kind,
      },
      collect: monitor => {
        return{
          isDragging: !!monitor.isDragging(),
        }
      },
    }
  }, [43])
  const Buttons = [
    {
      display: <Eye />,
      fkt: () => {
        if (props.open) { props.open() }
      },
      title: 'Ansehen',
      show: !!props.open
    },
    {
      display: <Edit />,
      fkt: () => {
        setStatus(Status.rename)
      },
      title: 'umbenennen',
      show: true
    },
    {
      display: <Trash />,
      fkt: (item: {id: number, name: string, delete: (fkt: () => void) => void}) => {
        if (!window.confirm(`${item.name} wirklich löschen?`)) { return }
        item.delete(props.reload)
      },
      title: 'löschen',
      show: !props.remove
    },
    {
      display: <Minus />,
      fkt: (item: {id: number, name: string}) => {
        if (!window.confirm(`${item.name} wirklich aus Liste entfernen?`)) { return }
        if (props.remove) { props.remove() }
      },
      title: 'entfernen',
      show: !!props.remove
    },
  ]
  return <div
      key={`item-${props.item.id}-${props.index}`}
      className={`w3-card w3-margin-top w3-padding ${props.className || ''} ${isDragging ? 'dragging' : ''}`}
      ref={drag}
    >
      {
        status !== Status.rename &&
        <h2>{props.item.name}</h2>
      }
      {
        status === Status.rename &&
        <Input
          value={props.item.name}
          cb={() => {}}
          onEnter={(value) => {
            props.item.rename(value, () => {
              setStatus(Status.done)
            })
          }}
        />
      }
      <div>
        {
          Buttons.map((b, i) => {
            if (!b.show) { return null }
            return <button
              key={`button-${i}`}
              className={`w3-button w3-border pointer`}
              title={b.title}
              onClick={() => b.fkt(props.item)}
            >
              {b.display}
            </button>
          })
        }
      </div>
    </div>
}