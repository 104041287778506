import config from '../config.json'
import axios from 'axios'
import broadcastService from './BroadcastService'
import rules from '../LoginGuard.json'
import UserDTO, {IUser} from '../dto/UserDTO'
import Com from './ComService'

export enum LoginStatus {
  loggedOut,
  user,
  admin
}

class LoginService {
  private status: LoginStatus = LoginStatus.loggedOut
  private tipInProgress: boolean = false
  public user: UserDTO | null = null
  public initDone: boolean = false

  constructor() {
    /*
    setInterval(() => {
      this.TestLogin()
    }, 5000)
    */
    this.TestLogin()
  }

  public IsAdmin () {
    return this.status === LoginStatus.admin
  }

  public IsUser () {
    return this.status === LoginStatus.user
  }

  private async TimeWaster(ms: number) {
    return new Promise(solve => {
      window.setTimeout(() => {
        solve(true)
      }, ms)
    })
  }

  public async IsLoggedIn () {
    return new Promise(async (solve) => {
      for (let i = 0; i < 10; i++) {
        if (this.initDone) {
        switch(this.status) {
            case LoginStatus.user:
            case LoginStatus.admin:
              solve(true)
          }
          solve(false)
        }
        await this.TimeWaster(500)
      }
    })
  }

  public async Login(name: string, password: string): Promise <number> {
    return new Promise(done => {
      Com('POST:user/login', {
        username: name,
        password: password
      }).then((result) => {
        console.log('login result', result)
        let status = LoginStatus.loggedOut
        status = LoginStatus.admin
        /*
        const roles = result.response.roles
        if (roles.some(r => r ==='ROLE_ADMIN')) {
          status = LoginStatus.admin
        } else if (roles.some(r => r ==='ROLE_USER')) {
          status = LoginStatus.user
        }
        */
        this.status = status
        broadcastService.broadcast('login', this.status)
        done(this.status)
      }).catch(err => {
      })
    })
  }

  public async LoginIntermediat(name: string, password: string) {
    const encodedLogin = window.btoa(`${name}:${password}`)
    const credentials = `Basic ${encodedLogin}`
    let headers = new Headers()
    headers.append('Authorization', credentials)
    headers.append('crossDomain', 'true')
    headers.append('withCredentials', 'true')
    const requestOptions = {
      headers: headers
    }
    let result = await new Promise <number> ((resolve, reject) => {
      fetch(`${config.apiPrefix}user/login`, requestOptions).then(async (response: any) => {
        const data = await response.json()
        console.log('login data', data)
        let status: number = LoginStatus.loggedOut
        const roles: string[] = data.response.roles
        if (roles.some(r => r ==='ROLE_ADMIN')) {
          status = LoginStatus.admin
        } else if (roles.some(r => r ==='ROLE_USER')) {
          status = LoginStatus.user
        }
        this.status = status
        broadcastService.broadcast('login', this.status)
        resolve(this.status)


      }).catch((err: any) => reject(err))
    })

    return result
  }

  public async LoginLegacy (name: string, password: string): Promise <number> {
    return new Promise(done => {
      axios(config.apiPrefix + 'user/login', {
        auth: {
          username: name,
          password: password
        }
      }).then((result: {data: {response: {roles: string[]}}}) => {
        let status = LoginStatus.loggedOut
        const roles = result.data.response.roles
        if (roles.some(r => r ==='ROLE_ADMIN')) {
          status = LoginStatus.admin
        } else if (roles.some(r => r ==='ROLE_USER')) {
          status = LoginStatus.user
        }
        this.status = status
        broadcastService.broadcast('login', this.status)
        done(this.status)
      }).catch(err => {
      })
    })
    
  }

  public async Logout () {
    await new Promise(done => {
      axios({
        method: 'get',
        url: config.apiPrefix + 'user/logout'
      }).then(() => {
        this.status = LoginStatus.loggedOut
        broadcastService.broadcast('logout', this.status)
        done(this.status)
      }).catch(err => {
      })
    })
    // Cron-Job
    axios(config.apiPrefix + 'cron/main')
    window.location.href = config.logoutTarget
  }

  /**
   * Test if User is still logged in
   */
  public async TestLogin() {
    if (this.tipInProgress) { return this.status }
    this.tipInProgress = true
    const result = await new Promise((done) => {
      Com('user/isloggedin', {}, 1).then((r) => {
        if ((r as {status: string}).status === 'okay') {
          if ((r as {response: {roles: string[]}}).response.roles.indexOf('ROLE_ADMIN') > -1) {
            this.status = LoginStatus.admin
          } else {
            this.status = LoginStatus.user
          }
          this.user = new UserDTO((r as {response: IUser}).response)
          done(true)
        } else {
          this.status = LoginStatus.loggedOut
          this.user = null
          done(false)
        }
      })
    })
    this.initDone = true
    this.tipInProgress = false
    broadcastService.broadcast(result ? 'login' : 'logout', this.status)
    return result
  }
}

const loginService = new LoginService()
export default loginService

export function LoginGuard() {
  const url = window.location.pathname
  if (rules.some(r => url.search(new RegExp(r)) > -1) && !loginService.IsLoggedIn()) {
    return true
  }
  return false
}
