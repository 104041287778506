// const days = ["monday", "tuesday", "wednesday", "thursday", "friday", "saturday", "sunday"]
const days = ["Mo", "Di", "Mi", "Do", "Fr", "Sa", "So"]
type Weekday = {
  name: string,
  points: Point[]
}
export type Point = {
  active: boolean,
  id: number,
  vId: number,
  hId: number,
  title?: string,
}
enum Status {
  standard,
  selectingInProgress
}

export default class TimeMatrixDTO {
  rawArr: Point[] = []
  status: Status = Status.standard
  startId: number = -1
  startVId: number = -1
  startHId: number = -1
  currentId: number = -1
  currentVId: number = -1
  currentHId: number = -1
  newValue: boolean = true
  days = days
  constructor(data: ITimeMatrix) {
    console.log('incoming', data)
    this.rawArr = this.initTimeString(data.timeString)
  }

  mouseDown(a: Point) {
    this.status = Status.selectingInProgress
    this.startId = a.id
    this.startVId = a.vId
    this.startHId = a.hId
    this.newValue = !a.active
  }

  mouseMove(a: Point) {
    if (this.status !== Status.selectingInProgress) { return false }
    this.currentId = a.id
    this.currentVId = a.vId
    this.currentHId = a.hId
    return true
  }

  mouseUp(a: Point) {
    if (this.status !== Status.selectingInProgress) { return }
    this.currentId = a.id
    this.currentVId = a.vId
    this.currentHId = a.hId
    this.rawArr = this.rawArr.map(a => {
      a = {
        ...a, ...{
          active: this.highlightPoint(a)
        }
      }
      return a
    })
    
    this.currentId = -1
    this.currentVId = -1
    this.currentHId = -1
    this.startId = -1
    this.startVId = -1
    this.startHId = -1
    this.status = Status.standard
  }

  highlightPoint(a: Point) {
    if (this.status !== Status.selectingInProgress || a.hId === -1 || a.vId === -1 ) { return a.active }
    const x = a.hId
    const y = a.vId
    if (this.isInInterval(x, this.startHId, this.currentHId) && this.isInInterval(y, this.startVId, this.currentVId)) {
      return this.newValue
    }
    return a.active
  }

  isInInterval(value: number, start: number, stop: number) {
    return (start <= value && value <= stop) || (stop <= value && value <= start)
  }

  makeEmptyString(count?: number) {
    let out = []
    for (let i = 0, m = (count || 24*7); i < m; i++) {
      out.push('0')
    }
    return out
  }

  initTimeString(timeString?: string) {
    if ((timeString?.length || 0) < 168) {
      console.log(timeString?.length)
      timeString = ''
    }
    let tss: string[] = timeString?.split('') || ['']
    if (tss.length < 168 || !tss) {
      tss = this.makeEmptyString()
    }
    // const tss: string[] = timeString?.split('') || this.makeEmptyString()
    console.log(tss.length)
    let out: Point[] = []
    let id: number = 0

    const dayLimit = 24*4
    let slotCounter = 0
    let dayCounter = 0
    tss.forEach(s => {
      const number = parseInt(s, 16)
      const hourString = this.prepebdZero24((number >>> 0).toString(2))
      const hourArr = hourString.split('')
      out = out.concat(hourArr.map(h => {
        id += 1
        slotCounter += 1
        if (slotCounter > dayLimit) {
          dayCounter += 1
          slotCounter = 1
        }
        return {
          id: id - 1,
          active: h === '1',
          vId: slotCounter - 1,
          hId: dayCounter,
          title: this.vId2Time(slotCounter - 1)
        }
        
      }))
    })
    return out
  }

  prepebdZero24(s: string) {
    switch(s.length) {
      case 0:
        return '0000'
      case 1:
        return '000' + s
      case 2:
        return '00' + s
      case 3:
        return '0' + s
      default:
        return s
    }
  }

  twoDigits(n: number) {
    let out = `${n}`
    if (out.length < 2) {
      return '0' + out
    }
    return out
  }

  generateTimeString(timeArr: Point[]) {
    let out: string = ''
    let part: string = ''
    let countEl: number = 0
    timeArr.forEach(t => {
      part += t.active ? '1' : '0'
      countEl += 1
      if (countEl > 3) {
        out += parseInt(part, 2).toString(16)
        countEl = 0
        part = ''
      }
    })
    return out
  }

  returnString(): string {
    return this.generateTimeString(this.rawArr)
  }

  getWeekdays(): Weekday[] {
    let out: Weekday[] = []
    const perDay = 24*4
    for (let i = 0, m = 7; i < m; i++) {
      out.push({
        name: days[i],
        points: this.rawArr.slice(i * perDay, (i + 1) * perDay)
      })
    }
    return out
  }

  vId2Time(id: number) {
    let h = Math.floor(id / 4)
    let m = (id - (h * 4)) * 15
    return `${h}:${this.twoDigits(m)} Uhr`
  }

  dateAllowed(d: Date) {
    let weekday = d.getDay() - 1
    if (weekday === -1) {
      weekday = 6
    }
    const hour = d.getHours()
    const minutes = d.getMinutes()
    const index = weekday * 24 * 4 + hour * 4 + Math.floor(minutes / 15)
    return this.rawArr[index].active
  }
}

interface ITimeMatrix {
  timeString?: string
}
