import config from '../config.json'
import axios from 'axios'
import loginService from './LoginService'

const commandRegex = '([a-zA-Z]+):(.*)'
const commandCacheRegex = '([a-zA-Z]+):([a-zA-Z0-9]+):(.*)'

enum Cache {
  false,
  true,
  set,
  get,
}

type Result = {data: {response: any}}

let cachedItems: {
  method: string,
  path: string,
  bodyHash: string,
  stamp: number,
  result: object,
}[] = []

function getCacheItem(method: string, path: string, body?: object, cacheTime?: number): Result | false {
  const bodyHash = JSON.stringify(body)
  const item = cachedItems.find((i) => {
    return i.method === method && i.path === path && i.bodyHash === bodyHash
  })
  if (item) {
    if (cacheTime && cacheTime > -1) {
      // Test if old result is still good
      const stamp = new Date().getTime()
      if (item.stamp < stamp - (cacheTime * 1000)) {

      }
    }
    return item.result as Result
  }
  return false

}
function setCacheItem(method: string, path: string, result: object, body?: object) {
  const bodyHash = JSON.stringify(body)
  cachedItems = cachedItems.filter((i) => {
    return !(i.method === method && i.path === path && i.bodyHash === bodyHash)
  })
  cachedItems.push({
    method: method,
    path: path,
    bodyHash: bodyHash,
    stamp: new Date().getTime(),
    result: result
  })
}

function dataLevelReturn(result: Result, level?: number) {
  switch(level) {
    case 0:
      return result
    case 1:
      return result.data
    case 2:
    default:
      return result.data.response
  }
}

/**
 * 
 * @param command string of structure KIND:actionpath aka GET:user/login or KIND:CACHE:actionpath aka GET:set:user/info . Where CACHE can be set, get, true, INT where INT is a number of seconds, which explains how old the result can be.
 * @param body body string of the request
 * @param level level of data returned: 0: result, 1 result.data, 2: result.data.response
 */
export default async function Com(command: string, body?: any, level?: number) {
  let method = 'get'
  let path = command + ''
  let cache = Cache.false
  let cacheTime = -1
  const r = command.match(commandRegex)
  const c = command.match(commandCacheRegex)
  if (c) {
    method = c[1]
    switch(c[2]) {
      case 'true':
        cache = Cache.true
        break
      case 'set':
        cache = Cache.set
        break
      case 'get':
        cache = Cache.get
        break
      default:
        cache = Cache.true
        cacheTime = parseInt(c[2], 10)
        break
    }
    path = c[3]
  } else if (r) {
    method = r[1]
    path = r[2]
  }
  if (cache === Cache.true || cache === Cache.get) {
    console.log(command + ' from cache')
    const cached = getCacheItem(method, path, body, cacheTime)
    if (cached) {
      dataLevelReturn(cached, level)
    }
  }
  return new Promise((success, reject) => {
    axios({
      method: method,
      url: config.apiPrefix + path,
      data: body
    }).then((result) => {
      if (cache === Cache.true || cache === Cache.set) {
        setCacheItem(method, path, result, body)
      }
      success(dataLevelReturn(result, level))
    }).catch(async (err) => {
      // Test if we are logged in
      if (await !loginService.IsLoggedIn()) {
        loginService.Logout()
      }
      // If error because of not authorizes: move to start-page

      // loginService.Logout()
      reject(err)
    })
  })
}
