export function RemoveDoublicates(arr: {id: number}[]) {
  let ids: number[] = []
  return arr.filter(a => {
    if (ids.indexOf(a.id) > -1) { return false }
    ids.push(a.id)
    return true
  })
}

export function UniqueIds(arr: number[]) {
  let ids: number[] = []
  return arr.filter(a => {
    if (ids.indexOf(a) > -1) { return false }
    ids.push(a)
    return true
  })
}
