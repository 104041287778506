import Com from "../services/ComService";
import {IItem as ISlot } from "./SlotAdminDTO";

export default class ScheduleAdminDTO {
  id: number
  screenId: number
  slot: {id: number, name: string}
  start: Date | null
  end: Date | null
  priority: number
  displayDuration: number
  repeats: number
  weekdays: string
  startTime: string
  endTime: string
  active: boolean
  constructor(data: ISchedule, slots: ISlot[]) {
    this.id = data.id
    this.screenId = data.screenId
    this.slot = {
      id: data.slotId,
      name: slots?.find(s => s.id === data.slotId)?.name || 'NA'
    }
    this.start = (data.start) ? new Date(data.start.date) : null
    this.end = (data.end) ? new Date(data.end.date) : null
    this.priority = data.priority
    this.displayDuration = data.displayDuration
    this.repeats = data.repeats
    this.weekdays = data.weekdays
    this.startTime = data.startTime
    this.endTime = data.endTime
    this.active = data.active
  }

  async save() {
    await Com('PATCH:admin/schedule', {
      id: this.id,
      screenId: this.screenId,
      slotId: this.slot.id,
      start: this.start,
      end: this.end,
      priority: this.priority,
      displayDuration: this.displayDuration,
      repeats: this.repeats,
      weekdays: this.weekdays,
      startTime: this.startTime,
      endTime: this.endTime,
      active: this.active,
    })
  }

  async delete() {
    await Com('DELETE:admin/schedule', {
      id: this.id
    })
  }

  async setWeekdays(weekdays: string) {
    this.weekdays = weekdays
    await this.save()
  }

  async setStartTime(s: string) {
    this.startTime = s
    await this.save()
  }

  async setEndTime(s: string) {
    this.endTime = s
    await this.save()
  }

  async setStart(s: Date | null) {
    this.start = s
    await this.save()
  }

  async setEnd(s: Date | null) {
    this.end = s
    await this.save()
  }

  async setPriority(v: number) {
    this.priority = v
    await this.save()
  }
}

export interface ISchedule {
  id: number
  screenId: number
  slotId: number
  start: {date: string} | null
  end: {date: string} | null
  priority: number
  displayDuration: number
  repeats: number
  weekdays: string
  startTime: string
  endTime: string
  active: boolean
}