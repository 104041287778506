
import Com from "../services/ComService";
import { ItemsDTO } from "./ItemsDTO";
import { ScreenAdminDTO } from "./ScreenAdminDTO";

export class ScreensDTO extends ItemsDTO {
  
  sourceUrl = 'admin/ugroup/screens'
  newItemUrl = 'admin/ugroup/screen'

  public initItems(items: IScreen[]) {
    this.items = items.map((i) => new ScreenAdminDTO({screen: i}))
  }

  public getItem(id: number) {
    return super.getItem(id) as ScreenAdminDTO
  }

  public async newItem(data: {name: string}) {
    await Com(`PUT:${this.newItemUrl}`, {
      groupId: this.groupId,
      name: data.name,
      description: '',
    })
  }
}

interface IScreen {
  id: number
  name: string
}
