import PropDTO, { IProp } from "./PropDTO"

export class SlotDTO {
  id: number
  props: PropDTO[]
  snippedIds: number[]
  constructor(item: ISlot) {
    this.id = item.id
    this.props = item.props.map(p => new PropDTO(p))
    this.snippedIds = item.snippedIds
  }
}

interface ISlot {
  id: number
  props: IProp[]
  snippedIds: number[]
}
