
import Com from "../services/ComService";
import { ItemsDTO } from "./ItemsDTO";
import { SlotAdminDTO } from "./SlotAdminDTO";
import { IItem } from "./SlotAdminDTO";

export class SlotsAdminDTO extends ItemsDTO {
  
  sourceUrl = 'admin/ugroup/slots'
  newItemUrl = 'admin/ugroup/slot'

  public initItems(items: IItem[]) {
    this.items = items.map((i) => new SlotAdminDTO(i))
  }

  public getItem(id: number) {
    return super.getItem(id) as SlotAdminDTO
  }

  public async newItem(data: {name: string}) {
    await Com(`PUT:${this.newItemUrl}`, {
      groupId: this.groupId,
      name: data.name,
      description: '',
    })
  }
}

