import { Outlet } from "react-router-dom"
import Menu from "../Menu/Menu"
import menuConfig from '../../config/userMenu.json'

const UserLayout = () => {
  return <div>
    <Menu
      config={menuConfig}
    />
    <Outlet></Outlet>
  </div>
}
export default UserLayout