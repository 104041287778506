import Com from "../services/ComService"
import FileDTO, { IFile } from "./FileDTO"

export default class PropDTO {
  sourceUrl: string
  id: number
  key1: string
  key2: string
  value1: string
  value2: string
  file: FileDTO | null = null

  constructor(item: IProp, sourceUrl?: string) {
    this.sourceUrl = sourceUrl || ''
    this.id = item.id
    this.key1 = item.key1
    this.key2 = item.key2
    this.value1 = item.value1
    this.value2 = item.value2
    if (item.file) {
      this.file = new FileDTO(item.file)
    }
  }

  getFileId() {
    if (!this.file) {
      return -1
    }
    return this.file.id
  }

  get() {
    return {
      id: this.id,
      key1: this.key1,
      key2: this.key2,
      value1: this.value1,
      value2: this.value2,
      fileId: this.getFileId(),
    }
  }

  async delete() {
    console.log('prop dto delete', this, this.id)
    // await this.file?.delete()
    await Com(`DELETE:${this.sourceUrl}`, {
      id: this.id
    })
  }
}

export interface IProp {
  id: number
  key1: string
  key2: string
  value1: string
  value2: string
  file: IFile | null
}