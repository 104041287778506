import Com from "../services/ComService";
import { ItemAdminDTO } from "./ItemAdminDTO";
import { IProp } from "./PropDTO";
import PropsDTO from "./PropsDTO";
import { SnippedAdminDTO, IItem as ISnipped } from "./SnippedAdminDTO";

export class SlotAdminDTO extends ItemAdminDTO {
  adminUrl = 'admin/slot'
  sourceUrl = 'admin/slot'
  hash: string = ''
  sortsnippeds: string = ''
  snippeds: SnippedAdminDTO[] = []
  props: PropsDTO = new PropsDTO({
    putTarget: 'admin/slot/prop',
    propSource: 'admin/slot/prop',
  })
  

  public init(data: IItem) {
    this.id = data.id
    if (this.id < 0) { return }
    this.name = data.name || this.name
    this.description = data.description || this.description
    this.hash = data.hash || this.hash
    this.sortsnippeds = data.sortSnippeds || this.sortsnippeds
    this.snippeds = (data.snippeds || []).map(s => new SnippedAdminDTO(s))
    if (!this.props) { return }
    this.props.init({
      parentId: data.id,
      props: data.props,
    })
  }

  public async addSnipped(id: number) {
    await Com('PUT:admin/slot/snipped', {
      id: this.id,
      snippedId: id,
      sortSnippeds: this.sortsnippeds,
    })
  }

  public async removeSnipped(id: number) {
    this.snippeds = this.snippeds.filter(s => s.id !== id)
    await Com('DELETE:admin/slot/snipped', {
      id: this.id,
      snippedId: id,
      sortSnippeds: this.sortsnippeds,
    })
  }
}

export interface IItem {
  id: number
  name?: string
  description?: string
  hash?: string
  version?: string
  groupId?: number
  slotIds?: number[]
  props?: IProp[]
  sortSnippeds?: string
  snippeds?: ISnipped[]
}