import { IProp } from "./PropDTO";
import PropsDTO from "./PropsDTO";

export enum SnippedKind {
  HTML = 1,
  IFRAME,
  CODE,
  WEB2PIC,
}

export const snippedKinds = [
  {
    kind: SnippedKind.HTML,
    name: 'HTML'
  },
  {
    kind: SnippedKind.IFRAME,
    name: 'IFRAME'
  },
  {
    kind: SnippedKind.CODE,
    name: 'CODE'
  },
  {
    kind: SnippedKind.WEB2PIC,
    name: 'WEB2PIC'
  },
]

export class SnippedDTO {
  id: number
  kind: number
  description: string
  displayDuration: number = 5
  name: string
  value: string
  props: PropsDTO = new PropsDTO({
    propSource: 'snipped/prop',
  })

  constructor(data: IItem) {
    this.id = data.id
    this.kind = data.kind
    this.description = data.description || ''
    this.name = data.name || ''
    this.displayDuration = data.displayDuration || -1
    this.value = data.value
    if (!this.props) { return }
    this.props.init({
      parentId: data.id,
      props: data.props,
    })
  }

  getDisplayDuration() {
    return this.displayDuration * 1000
  }

  generateStyle() {
    if (this.kind === SnippedKind.WEB2PIC) {
      const link = `https://suha.duia.eu/web2picApi/index.php?cache=60&target=${this.value}`
      const style = {...generateStyle(this), ...{
        backgroundImage: `url("${link}")`,
      }}
      console.log('style:', style)
      return style
    }
    return generateStyle(this)
  }
}

export function generateStyle(item: {props: PropsDTO}) {
  return {
    backgroundColor: item.props.getPropVal1('style', 'bgcolor') || '#ffffff',
    padding: (item.props.getPropVal1('style', 'padding') + 'px') || '10px',
    overflow: 'hidden',
    backgroundImage: item.props.getPropFileBGString('file', 'bg'),
    backgroundSize: item.props.getPropVal1('bg', 'size') || 'cover',
    backgroundRepeat: item.props.getPropVal1('bg', 'repeat') || 'no-repeat',
    backgroundPosition: item.props.getPropVal1('bg', 'position') || 'top left',
  }
}

export interface IItem {
  id: number
  kind: number
  value: string
  displayDuration: number
  name: string
  description?: string
  version?: string
  groupId?: number
  props?: IProp[]
}
