import { useState } from "react"
import i18n from "../../services/i18n"
import Com from "../../services/ComService"
import Input from "../Input/Input"
import PictureUploader from "../File/PictureUploader"
import PictureList from "../File/PictureList"
import { Trash } from "react-feather"
import userService from "../../services/UserService"

enum Status {
  done,
  error
}

const PasswordChanger = () => {
  const [new2Password, setnew2Password] = useState('')
  const [newPassword, setNewPassword] = useState('')
  // const [status, setStatus] = useState(Status.done)
  const [Notice, setNotice] = useState('')
  return <>
    <h2>{i18n.get('editPassword')}</h2>
      <Input
        value={new2Password}
        placeholder={i18n.get('newPassword')}
        cb={(pw) => setnew2Password(pw)}
        type='password'
      />
      <Input
        value={newPassword}
        placeholder={i18n.get('repeatNewPassword')}
        cb={(pw) => setNewPassword(pw)}
        type='password'
      />
      <button
        className='w3-button w3-ripple w3-yellow'
        onClick={async () => {
          if (new2Password != newPassword) {
            setNotice('err:' + i18n.get('checkPasswordSpelling'))
            return
          }
          const r = await Com('PATCH:user/password', {
            newPassword: newPassword
          }, 1) as {status: string}
          switch (r.status) {
            case 'ok':
              setNotice('suc:' + i18n.get('passwordChanged'))
              break
            case 'short password':
              setNotice('err:' + i18n.get('shortPassword'))
              break
            default:
              setNotice('err:' + i18n.get('error'))
              break
          }
        }}
      >{i18n.get('setNewPassword')}</button>
    {
      Notice != '' &&
      <div className={`w3-card w3-center w3-padding w3-border w3-margin ${Notice.search(/^suc:/) === 0 ? 'w3-text-teal w3-border-teal' : ''} ${Notice.search(/^err:/) === 0 ? 'w3-text-red w3-border-red' : ''}`}>
        {
          Notice.slice(4)
        }
      </div>
    }
  </>
}

const UploadFile = () => {
  return <div className='w3-container w3-container-auto w3-padding w3-margin'>
    <PictureUploader
      cb={() => {
        console.log('upload done')
      }}
      path='user/picture'
    />
  </div>
}

const UserPreferences = () => {
  
  return <div className='UserPreferences w3-container w3-container-auto'>
    <PasswordChanger />
    <h2>Dateien</h2>
    <UploadFile />
    <PictureList kind='userPictures' />
    <h2>{i18n.get('deleteUser')}</h2>
    <p>{i18n.get('deleteUserOk')}<button
      className='w3-button w3-ripple w3-red w3-padding w3-margin'
      onClick={async () => {
        if(window.confirm(i18n.get('deleteUserOk'))) {
          await userService.user?.delete()
          window.location.href = '/'
        }
      }}
    ><Trash /></button></p>
  </div>
}
export default UserPreferences
